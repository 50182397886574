<template>
  <div class="full">
    <div class=" mess ">
      <!-- <div class="col-2"></div> -->
      <div class="fullshare  m-0 p-0">
        <div class="lefti">
          <img  src="../assets/img/drive/6.webp" loading="lazy" alt="drive-6" >
        </div>
        <div class="leftc">
          <img  src="../assets/img/drive/2.webp" loading="lazy" alt="drive-2" >
        </div>
  <div class="leftr">
    <img  src="../assets/img/drive/5.webp" loading="lazy" alt="drive-5">
  </div>
      </div>
      <div class=" con">
    <form @submit.prevent="sendEmail" class="contact-form">
      <h5 class="title name" :class="{ 'tamil-fonts': selectedLanguage === 'ta', 'normal-fonts': selectedLanguage !== 'ta' }">{{ translations.contactUs }}</h5>
      <p class="description">
        {{ translations.contactDescription }}
      </p>
      <div>
        <input
          type="text"
          v-model="name"
          class="form-control rounded border-white mb-3 form-input"
          :placeholder="translations.namePlaceholder"
          required
        />
      </div>
      <div>
        <input
          type="email"
          v-model="email"
          class="form-control rounded border-white mb-3 form-input"
          :placeholder="translations.emailPlaceholder"
          required
        />
      </div>
      <div>
        <textarea
          id="message"
          v-model="message"
          class="form-control rounded border-white mb-3 form-text-area"
          rows="5"
          cols="30"
          :placeholder="translations.messagePlaceholder"
          required
        ></textarea>
      </div>
      <div class="submit-button-wrapper">
        <input type="submit" :value="translations.sendButton" />
      </div>
      <div v-if="showSuccessMessage" class="success-message">
        {{ translations.successMessage }}
      </div>
    </form>
  </div>
  <!-- <div class="col-2"></div> -->

  </div>
  <div class="row address">
    <div class="col ">
      <p>   {{ translations.address }}</p>
      <h6>
M/s. VAYAL AGRO TECH (OPC) PRIVATE LIMITED
</h6>
<h6>28 NCN GARDEN, </h6>
<h6>THIRUNAGAR (OPPOSITE TO MUTHU MAHAL ), </h6>
<h6>GOBICHETTIPALAYAM, </h6>
<h6>ERODE, TAMIL NADU.</h6>
    </div>
    <div class="col right"><p>        {{ translations.emails }}
</p>
    <h6><a class=" " href="mailto:support@vayalagro.com">support@vayalagro.com</a></h6>
    <!-- <h6>teamvayal@gmail.com </h6> -->
    </div>
  </div>
  <div class="mapsection mt-3 ">
    <div class="map">
    <section class="g-map">
    <div class="map" id="map">
      <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d988.3330994120984!2d77.41775309143928!3d11.458840452428094!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTHCsDI3JzMxLjgiTiA3N8KwMjUnMDQuNiJF!5e0!3m2!1sen!2sin!4v1691988195977!5m2!1sen!2sin" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
  </section>
  </div>
  <div class="carousel"></div>
  </div>

  </div>
</template>


<style scoped>
.con{
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 99;
}
.name{
  text-align: center;
  font-family: "Satisfy", sans-serif;
  color: #4E7300;
  font-weight: 500;
margin: 50px 0px 0px 0px;
position: relative;

}
.name span{
  transform: rotate(-deg); 
  position: absolute;
z-index: 1;
margin: -35px;

}
.hover-fx {
  font-size: 36px;
  display: inline-block;
  cursor: pointer;
  margin-right: 15px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  text-align: center;
  position: relative;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  transition: 300ms;
}

.hover-fx:after {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  content: "";
  box-sizing: content-box;
  box-shadow: 0 0 0 3px #fff;
  top: 0;
  left: 0;
  opacity: 0;
  transition: 300ms;
}

.hover-fx:hover {
  background-color: #fff;
  color: #00989a;
}

.hover-fx:hover:after {
  opacity: 1;
  transform: scale(1.15);
}


.contact-form-wrapper {
  padding: 0px 0;
}
.contact {
  display: flex;
  justify-content: center;
}
.contact-form {
  padding: 30px 40px;
  border-radius: 0px;
  height: 500px;
}

.contact-form textarea {
  resize: none;
}

.contact-form .form-input
 {
  background-color: #f0f4f5;
  height: 60px;
  padding-left: 16px;
}
.form-text-area{
  background-color: #f0f4f5;
  height: 100px;
  padding-left: 16px;
}

.contact-form .form-text-area {
  background-color: #f0f4f5;
  height: auto;
  padding-left: 16px;
}

.contact-form .form-control::placeholder {
  color: #aeb4b9;
  font-weight: 500;
  opacity: 1;
  font-size: 12px;

}

.contact-form .form-control:-ms-input-placeholder {
  color: #aeb4b9;
  font-weight: 500;
}

.contact-form .form-control::-ms-input-placeholder {
  color: #aeb4b9;
  font-weight: 500;
}

.contact-form .form-control:focus {
  border-color: #025721;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.07), 0 0 8px #025721;
}

.contact-form .title {
  text-align: center;
  font-weight: 500;
  color: #4E7300;
}

.contact-form .description {
  color: #aeb4b9;
  font-size: 14px;
  text-align: center;
}

.contact-form .submit-button-wrapper {
  text-align: center;

}

.contact-form .submit-button-wrapper input {
  border: none;
  border-radius: 4px;
  background-color: #71a600;
  color: white;
  text-transform: uppercase;
  margin: 0px 0px 0px 0px;
  padding: 15px 80px;
  font-weight: 600;
  letter-spacing: 2px;
  font-size: 14px;

}

.contact-form .submit-button-wrapper input:hover {
  background-color: #e5dbe0;
  color: #71a600;
  transition: background-color 0.3s;

}
.contact-form .submit-button-wrapper input:not(:hover) {
  transition: background-color 0.3s;
}
.mess{
  height: auto;
  margin: 25px 0px 25px 0px;
}
.success-message{
  color: #4E7300;
font-size: 14px;
display: flex;
margin: 5px 0px;
justify-content: center;
}
.address{
  margin: 0px 100px 0px 100px;
  padding: 50px 50px;
  background-color: #f0f4f5;
  border-radius: 8px;
}
.address h6{
  color: #4E7300;
}

.address h6 a{
  text-decoration: none;
  color: #4E7300;
}
.address p{
  color:#4E7300;

}
.right{
  justify-content: right;
  text-align: right;
}
.normal-fonts {
	font-size: 35px;
  }
  
  .tamil-fonts {
	font-size: 25px;
  }
@media (max-width: 768px) {
.con{
  margin: 10px;
}
.contact-form {
  padding: 30px 10px;
  background-color: #ffffff;
  border-radius: 0px;
  height: 500px;
}

.mess{
  height: auto;
  margin: 25px 0px 45px 0px;
  padding-bottom: 55px;
  border: none;
}
.address{
  margin: 0px 10px 50px 10px;
  padding: 10px 10px;
  background-color: #f0f4f5;
  border-radius: 8px;
}
.address h6{
font-size: 14px;
}
.address p{
  color: rgba(70, 67, 67, 0.8);
  font-size: 14px;


}
}
@media (min-width: 1800px) {
  .full{
    margin: 0 10%;
  }
   .fullshare .lefti img{
  margin: 0 10%;
  }
  .fullshare .leftr img{
  margin: 0 10%;
  }
  }
</style>
<script>
import { en, ta } from "@/translations";
import emailjs from 'emailjs-com';

const EMAILJS_USER_ID = '8BXWKT4hWl3AcLvXv';

export default {
  props: ["selectedLanguage"],
  data() {
    return {
      name: '',
      email: '',
      message: '',
      showSuccessMessage: false,
    };
  },
  computed: {
    translations() {
      return this.selectedLanguage === "en" ? en : ta;
    },
  },
  methods: {
    sendEmail() {
      const templateParams = {
        from_name: this.name,
        from_email: this.email,
        to_name: 'Team Vayal', // Replace with the recipient's name
        message: this.message,
      };

      emailjs
        .send(
          'service_cs61s16', // Replace with your EmailJS service ID
          'template_wlbwphz', // Replace with your EmailJS template ID
          templateParams,
          EMAILJS_USER_ID // Use the User ID directly here
        )
        .then((response) => {
           console.log('Email sent successfully!', response);
          this.showSuccessMessage = true; // Display the success message
          this.resetForm(); // Clear the form fields
          setTimeout(() => {
            this.showSuccessMessage = false; // Hide the success message after some time (optional)
          }, 3000); // Adjust the time (in milliseconds) as needed
        })
        .catch((error) => {
          console.error('Error sending email:', error);
          // Handle error: display an error message or perform any other actions
        });
    },
    resetForm() {
      this.name = '';
      this.email = '';
      this.message = '';
    },
  },
    beforeRouteLeave(to, from, next) {
 if (to.name === "Home") {
      window.location.reload();
  } else {
    next();
  }
}
};
</script>
<style src="../assets/img/css/design.css"></style>
