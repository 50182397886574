<template>
  <div>
    <div class="containe listdate">
      <div class="colourmaker">
        <div class="tpic-align-cenetr">
        <div class="onplace">
         <h1 class="mx-4">
  {{ selectedLanguage === "en" ? 
    this.$route.params?.categoryName?.replace(/-/g, ' ')?.replace(/\b\w/g, char => char.toUpperCase()) : 
    selectedProduct
  }} 
 {{ selectedLanguage === "en" 
  ? translations.marketPrice + (isToday(created) ? ' ' + translations.today : '') 
  : translations.today + ' ' + translations.marketPrice + (isToday(created) ? '' : '') 
}} 
  {{ selectedLanguage === "en" ? 
    (this.$route.params.location?.startsWith('-') ? 
      this.$route.params.location?.split('-')[1]?.replace(/\b\w/g, char => char.toUpperCase()) || 'Tamilnadu' : 
      this.$route.params?.location?.replace(/\b\w/g, char => char.toUpperCase()) || 'Tamilnadu') : 
    selectedDistrict ?? translations.tamilnadu
  }}
  {{ '(' + formatDate(created) + ')' }}
</h1>

        </div>
        <div v-if="content" class="containers content mt-3 mx-4">
          <p>{{ content }}</p>
        </div>
      </div>
      <div class="loco searchbox d-md-flex justify-content-center">

        <div class="dist mx-3">
        <h6>{{ translations.getcate }}</h6>
        <select :class="{ 'tamil-font': selectedLanguage === 'ta', 'selec': selectedLanguage !== 'ta' }" v-model="selectedCategory" searchable>
          <option value="0" disabled >{{ translations.selectProduct }}</option>
          <option v-for="category in categories" :key="category.category_id" :value="category.category_id" >
            {{ selectedLanguage === "en" ? category.category_name : category.tn_name }}
          </option>
        </select> 
      </div>
    
      <div class="dist mx-3">
        <h6>{{ translations.selectdist }}</h6>
        <select  :class="{ 'tamil-font': selectedLanguage === 'ta', 'selec': selectedLanguage !== 'ta' }" v-model="selectedMarket" searchable :disabled="!markets.length">
          <option value="0" disabled >{{ translations.selectDist1 }}</option>
          <option v-for="market in markets" :key="market.market_id" :value="market.market_id" 
          >
            {{ selectedLanguage === "en" ? market.market_name : market.tn_name }}
          </option>
        </select> 
      </div>
      <!-- <div v-else>
        <p>No markets available</p>
      </div> -->

      <div class="town mx-3">
        <h6>{{ translations.selecttown }}</h6>
        <select :class="{ 'tamil-font': selectedLanguage === 'ta', 'selec': selectedLanguage !== 'ta' }" v-model="selectedPlace" :disabled="!placeData.length">
          <option value="0">{{ translations.all }}</option>
          <option v-for="place in placeData" :key="place.market_place_id" :value="place.market_place_id">
            {{ selectedLanguage === "en" ? place.place : place.tn_place }}
          </option>
        </select>
      </div>

      <div class="town mx-3">
      <h6>{{ translations.date }}</h6>
      <input 
        type="date" 
        class="form-control date-formate" 
        aria-label="Large" 
        aria-describedby="inputGroup-sizing-sm"
        v-model="created"
        :max="currentDate"
        :min="minDate"
      />
      </div>

      <div class="dist mx-3" @click="searchMarketData">
      <button 
    :disabled="selectedMarket == '0' || selectedCategory == '0'" 
    :class="{ 'disabled-red': selectedMarket == '0' || selectedCategory == '0' }"
  > 
    Search 
  </button>
      </div>
    </div>


        <div class="table-container text-center mt-5" ref="MarketTable"> 
          <div v-if="isLoading">
            {{console.log("v-if",this.isLoading)}}
            <!-- Shimmer effect for loading -->
            <div v-for="index in 6" :key="index" class="container">
              <p class="shimmer-line" style="height: 40px"></p>
              <p class="shimmer-line" style="height: 40px"></p>
              <p class="shimmer-line" style="height: 40px"></p>
            </div>
          </div>
          <div v-else-if="(sublistData.length || (tumpData.length && !this.$route.params.location) )">
            {{console.log("v-else-if",this.sublistData,"-",this.tumpData)}}
            <div v-for="(date, index) in (sublistData.length ? sublistData : tumpData)" :key="date.uniqueId" class="showdist">
              <!-- Check if it's the 0th element -->
              <h2 v-if="index === 0">
                
                <span v-if="sublistData.length">
                  {{
                    this.searchedPlace != '0' ?
                    selectedLanguage === "en"
                    ? date.place + ','
                    : date.tn_place + ',' : ''
                  }}
                  {{
                    selectedLanguage === "en"
                    ? date.market_name
                    : date.tn_name
                  }},
                  {{ sublistDate(date.created)}}
                </span>
                <span v-else-if="tumpData.length && !this.$route.params.location">Recent Market Price</span>
                <!-- <span v-if="tumpData.length && !Object.keys(this.$route.params.location).length && !sublistData.length">{{ formatDate(date.created) }}</span> -->
              </h2>

              
            </div>
            
                  <div class="d-sm-none">

            <table class="table table-responsive  text-center px-md-5 px-sm-2 px-2 ">
              <thead>
                <tr class="text-nowrap head">
                  <th>{{translations.category}}</th>
                  <th>{{translations.price}}/{{translations.quantity}}</th>
                  
                </tr>
              </thead>
              <tbody>
                <tr v-for="date in (sublistData.length ? sublistData : tumpData)" :key="date.uniqueId" class="text-nowrap">
                  <!-- Display relevant information from the response -->
    
                  <td>
                   {{
                      selectedLanguage === "en"
                      ? date.sub_category_name
                      :  date.tn_sub_name
                    }}
                  <br> <small>Id: {{ date.uniqueId }}</small>
                  </td>
                  <td> <i class="fa fa-inr" aria-hidden="true"></i>
                    {{ date.price }}/ {{ selectedLanguage === 'en'
                  ? date.quantity_type
                  : date.quantity_type_tamil }} 
                    <br>
                    <router-link class="history-icon" :to="{
                      name: 'AllRecent',
                      params: {
                        ...this.$route.params,
                        name: date.sub_category_name.trim().replace(/\s+/g, '-').toLowerCase() || '',
                        categoryName: date.category_name.replace(/\s+/g, '-').toLowerCase(),
                        location: '-' + date.market_name.replace(/\s+/g, '-').toLowerCase(),
                        city: date.place.replace(/\s+/g, '-').toLowerCase(),
                      },
                    }" target="_blank">{{ translations.history }} &nbsp;
                    <i class="fas fa-chart-line"></i></router-link>
                    
                    </td>
                
                  
                </tr>
              </tbody>
            </table>
                  </div>
<div class="table-1-web d-none d-sm-block">
          <table class="table table-responsive  text-center px-md-5 px-sm-2 px-2 ">
              <thead>
              
                <tr class="text-nowrap head">
                  <th>{{translations.category}}</th>
                  <th >{{translations.selectdist}}</th>
                  <th >{{translations.city}}</th>
                  <th>{{translations.price}}/{{translations.quantity}}</th>
                
                </tr>
              </thead>
              <tbody>
                <tr v-for="date in (sublistData.length ? sublistData : tumpData)" :key="date.uniqueId" class="text-nowrap">
                  <!-- Display relevant information from the response -->
    
                  <td>
                   {{
                      selectedLanguage === "en"
                      ? date.sub_category_name
                      :  date.tn_sub_name
                    }}
                  <br> <small>Id: {{ date.uniqueId }}</small>
                  </td>
                  <td class="text-nowrap">
                    {{ selectedLanguage === "en"
                      ? date.market_name
                      : date.tn_name
                    }}
                  </td>
                  <td class="text-nowrap">
                    {{
                      selectedLanguage === "en"
                      ? date.place
                      : date.tn_place
                    }}
                  </td>
                  <td> <i class="fa fa-inr" aria-hidden="true"></i>
                    {{ date.price }}/ {{ selectedLanguage === 'en'
                  ? date.quantity_type
                  : date.quantity_type_tamil }} 
                    <br>
                    <router-link class="history-icon" :to="{
                      name: 'AllRecent',
                      params: {
                        ...this.$route.params,
                        name: date.sub_category_name.trim().replace(/\s+/g, '-').toLowerCase() || '',
                        categoryName: date.category_name.replace(/\s+/g, '-').toLowerCase(),
                        location: '-' + date.market_name.replace(/\s+/g, '-').toLowerCase(),
                        city: date.place.replace(/\s+/g, '-').toLowerCase(),
                      },
                    }" target="_blank">{{ translations.history }} &nbsp;
                    <i class="fas fa-chart-line"></i></router-link>
                    
                    </td>
                  
                  
                </tr>
              </tbody>
            </table>
</div>
          </div>
          <div v-else-if="!sublistData?.length && this.$route.params.location">
            {{console.log("v-else",this.sublistData,"-",this.tumpData)}}
             <div class="nodata-img">
              <img class="" src="../assets/img/why/emptypro.webp" loading="lazy" alt="empty">
            </div>
              <h2 class="my-3 no-data">
                {{ translations.no_data_market }} 
               </h2>
               
               <p class="no-data-para">{{ translations.no_data_found }}</p>
           
          </div>

            <h2 class="todays-head" v-if="this.$route.params.categoryName && Newmarket.length > 1">
  {{ translations.todays }} 
  {{ selectedLanguage === 'en' ? 
    this.$route.params?.categoryName?.replace(/-/g, ' ')?.replace(/\b\w/g, char => char.toUpperCase()) : 
    selectedProduct 
  }} 
  {{ translations.marketPrices1 }} {{ translations.tamilnadu }}
</h2>
<div class="market-container">

  <div
    v-for="(market, index) in Newmarket"
    :key="market.market_id"
    :value="market.market_id"
    class="inter-links"
  >
    <span
      v-if="this.$route.params.categoryName && this.$route.params.location !== '-' + market.market_name.replace(/\s+/g, '-').toLowerCase()"
      @click="navigateToMarketPrice(market)"
    >
    {{ selectedLanguage === 'en' ? market.market_name : market.tn_name }} {{ selectedLanguage === 'en' ? 
      this.$route.params?.categoryName?.replace(/-/g, ' ')?.replace(/\b\w/g, char => char.toUpperCase()) : 
      selectedProduct
      }} {{ translations.marketPrice  }}
      
      <span v-if="index !== Newmarket.length - 1"> | </span>
    </span>
  </div>
</div>

        </div>
      </div>
    </div>

    <nav v-if="sublistData.length">
      <ul class="pagination">
    <!-- First Page -->
    <li :class="{ disabled: currentPage === 1 }">
      <a @click="onPageChange(1)" aria-label="First">
            <span aria-hidden="true">
          <i class="fa fa-chevron-left" aria-hidden="true"></i>
          <i class="fa fa-chevron-left" aria-hidden="true"></i>
        </span>
      </a>
    </li>

    <!-- Previous Page -->
    <li :class="{ disabled: currentPage === 1 }">
      <a @click="onPageChange(currentPage - 1)" aria-label="Previous">
            <span aria-hidden="true">
          <i class="fa fa-chevron-left" aria-hidden="true"></i>
        </span>
      </a>
    </li>

        <li :class="{ active: currentPage === pageNumber }" v-for="pageNumber in pageNumbers" :key="pageNumber">
          <a @click="onPageChange(pageNumber)">{{ pageNumber }}</a>
    </li>
        <!-- Next Page Button -->
    <li :class="{ disabled: currentPage === totalPages }">
      <a @click="onPageChange(currentPage + 1)" aria-label="Next">
            <span aria-hidden="true">
          <i class="fa fa-chevron-right" aria-hidden="true"></i>
        </span>
      </a>
    </li>

        <!-- Last Page Button -->
    <li :class="{ disabled: currentPage === totalPages }">
      <a @click="onPageChange(totalPages)" aria-label="Last">
            <span aria-hidden="true">
          <i class="fa fa-chevron-right" aria-hidden="true"></i>
          <i class="fa fa-chevron-right" aria-hidden="true"></i>
        </span>
      </a>
    </li>
  </ul>
</nav>
     <div class="disclaimer">
      <p class="text-center my-3 container" v-if="disclaimer"><strong>Disclaimer:</strong> {{ disclaimer }}</p>
    </div>

  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { disableScroll } from '@/router';
import { en, ta } from "@/translations";
import { useItemStore, useUserStore } from "@/stores/userStore";
import { mapActions, mapState } from "pinia";

export default {
  props: ["selectedLanguage"], // Receive the selectedLanguage prop from App.vue

  data() {
    return {
      marketName: null,
      categoryName: null,
      sublistData: [],
      isLoading: true,
      place: null,
      totalItems: 0,
      itemsPerPage: 10, // Example: set the default number of items per page
      currentPage: 1,
      totalPages: 0, // Add this property to store the total number of pages
      selectedMarket: '0',
      markets: [],
      Newmarket:[],
      tumpData:[],
      selectedPlace: "0",
      placeData: [],
      selectedCategory: '0',
      categories: [],
      dates: [],
      chooseDate:'',
      // created: this.selectedDate || new Date().toISOString().split('T')[0],
      isFreshPage: this.$route.params.location ? true : false,
      created: new Date().toISOString().split('T')[0],
      currentDate: new Date().toISOString().split('T')[0],
      minDate: this.getMinDate(),
      selectedProduct:'',
      selectedDistrict:'',
      searchedPlace: '0',
      subListDataFetched: false,
      isPlaceDataFetched: false,
    };
  },
  head() {
    return {
      script: [
        {
          type: 'application/ld+json',
          json:{
            "@context": "https://schema.org",
            "@type": "WebPage",
            "@id": "https://vayalagro.com/market-price",
            "url": "https://vayalagro.com/market-price",
            "name": "Market Prices in Tamil Nadu",
            "description": "Get the latest market prices for various agricultural products and livestock in Tamil Nadu. Stay informed for better trading decisions.",
            "inLanguage": "en-US",
            "datePublished": "2024-10-17T00:00:00+00:00",
            "dateModified": "2024-10-21T00:00:00+00:00",
            "breadcrumb": {
              "@type": "BreadcrumbList",
              "@id": "https://vayalagro.com/market-price/#breadcrumb",
              "itemListElement": [
                {
                  "@type": "ListItem",
                  "position": 1,
                  "name": "Home",
                  "item": "https://vayalagro.com/"
                },
                {
                  "@type": "ListItem",
                  "position": 2,
                  "name": "Market Prices",
                  "item": "https://vayalagro.com/market-price"
                }
              ]
            }
          }
        }
      ]
    };
  },
  computed: {
    ...mapState(useItemStore, ['categoryId', 'subCategoryId']),
    ...mapState(useUserStore, ['marketId', 'marketPlaceId']),
    selectedDate() {
      return this.$router.history?.state?.selectedDate || false;
      
    },
    translations() {
      return this.selectedLanguage === "en" ? en : ta;
    },
    formatDate() {
      return date => moment(date).format("DD-MM-YYYY");
    },

    sublistDate(){
      return date => date.split('/').join('-');
    },
    pageNumbers() {
      const visiblePageCount = 3; // Number of page numbers to display
      const startPage = Math.max(
        1,
        this.currentPage - Math.floor(visiblePageCount / 2)
      );
      const endPage = Math.min(
        this.totalPages,
        startPage + visiblePageCount - 1
      );

      return Array.from(
        { length: endPage - startPage + 1 },
        (_, i) => startPage + i
      );
    }
  },
  beforeCreate() {
    
    if (!Object.keys(this.$route.params).length) {
      this.$route.params = {
        location : '',
        categoryName : '',
        city: '',
        created: '',
        categoryId: ''
      }
      if (!this.selectedCategory) this.selectedCategory = '0';
      if (!this.selectedMarket) this.selectedMarket = '0';
      if (!this.selectedPlace) {
        // console.log('mpId to 0 (0)')
        this.selectedPlace = '0';
      }
    }
    if (this.selectedDate) {
      this.created = this.selectedDate
       disableScroll();
      console.log('created set from state!',this.selectedDate)
    }
  },
  watch: {
    categoryId(newVal, oldVal) {
      if(newVal !== oldVal) {
        // this.fetchMarketData();
        // this.updateMarketPlaceId(this.$route.params?.city?.replace(/-/g,' '),this.categoryId,this.marketId)

      } 
    },
    subCategoryId(newVal) {
      console.log('SubCategory ID changed:', newVal);
    },
    marketId(newVal, oldVal) {
      // console.log('Market ID changed:', newVal);
      if(newVal !== oldVal && this.marketId != '0' && this.categoryId != '0' && !this.isPlaceDataFetched) this.fetchPlaceData();
      
    },
    marketPlaceId(newVal, oldVal) {
      console.log('Marketplace ID changed from:',oldVal, ' to ', newVal);
    },
    // Watch for changes in the route parameters
    '$route.params': {
    handler(newParams, oldParams) {
      // console.log('route params watcher called...')
      this.updateMetaTags();
      this.handleRouteChange(newParams, oldParams);
    },
    deep: true,
    immediate: true,
  },
  selectedMarket: {
    handler(newVal, oldVal) {
      console.log('selectedMarket watcher called... new:',newVal, 'old:', oldVal)
      if (this.selectedMarket != this.marketId) {
        // console.log("mId changing...") 
        this.updateMarketId(this.selectedMarket);
        } else {
        // console.log("not changed")
      }
      if(!this.subListDataFetched && this.$route.params.categoryName && this.selectedCategory != '0' && this.selectedMarket != '0' && !this.$route.params?.city) {
        // console.log("line-454", this.$route.params)
        this.fetchSublistData()
        this.isFreshPage = false;
      } else {
        // console.log("mId not changed here", this.subListDataFetched, )
      }
      this.currentPage = 1; // Reset the current page to 1 when the market changes
      if (this.selectedMarket != '0') {
        // this.selectedPlace = '0';
        this.fetchPlaceData();
        }
    },
    immediate: true,
},
  selectedPlace: {
    handler(newPlace, oldPlace) {
      // console.log('newPlace:',newPlace,"oldPlace:",oldPlace,'marketPlaceId',this.marketPlaceId)
      if (this.selectedPlace != this.marketPlaceId) {
        // console.log("mpId changing...") 
        this.updateMarketPlaceId(newPlace);
      
           
      
      if((!this.subListDataFetched && this.$route.params.categoryName) && this.selectedPlace != '0' && Object.keys(this.$route.params).length && newPlace !== oldPlace && this.categoryId && this.marketId) {
        // console.log('line reload sublist')
       this.fetchSublistData();

        this.isFreshPage = false;
      } 
      else {
      // console.log('line reload sublist not called')
      }
      
      this.currentPage = 1; // Reset the current page to 1 when the place changes
      } else {
        // console.log("mpId not changed")
      }
  
    },
    immediate: true,
  },
  selectedCategory: {
    
    handler() {

      if(this.selectedCategory!= '0'){
        this.fetchMarketData()
      }
      if (this.selectedCategory != this.categoryId) {
        // console.log("cat changing...") 
        this.updateCatId(this.selectedCategory)
      } else {
        // console.log("cat not changed")
      }
       this.currentPage = 1;
    }
  },
  created: {
  // when changing date, data should come bcoz we are not passing in url to fetch when search
    handler() {
      if(this.selectedCategory && this.selectedCategory != '0' && this.selectedMarket && this.selectedMarket != '0') {
        console.log("line-498")
        this.fetchSublistData(this.currentPage = 1)
        this.isFreshPage = false;
      }
      
    }
  }
 
  },
  beforeUnmount() {
    this.sublistData = [],
    this.markets = [],
    this.tumpData = [],
    this.placeData = [],
    this.categories = [],
    this.dates = []
  },

  async created() {
    this.fetchCategoryData(true);
    if (this.selectedCategory) {
      await this.fetchMarketData();
    }
    if (this.selectedCategory != '0' && this.selectedMarket != '0') {
      console.log("line-521")
     
    }

    if(!this.$route.params.location){
     await this.fetchInitData();
    }
        this.updateMetaTags(); 

  },
 
  mounted() {
   
    this.marketName = this.$route.params?.location;
    this.categoryName = this.$route.params?.categoryName?.replace(/-/g, ' ');
  },
  methods: {
    ...mapActions(useItemStore, ['updateItemId','updateMarketCatId','updateSubCatId','updateCatId']),
    ...mapActions(useUserStore, ['updateMarketId','updateMarketIdByDist','updateMarketPlaceId','updateMarketPlaceIdByCity']),
    redirectToContact() {
      this.$router.push("/contact");
    },
    updatePaginatedData() {
    const start = (this.currentPage - 1) * this.itemsPerPage;
    const end = start + this.itemsPerPage;
    this.paginatedData = this.sublistData.slice(start, end);
  },
  onPageChange(page) {
    this.$nextTick(() => {
            const container = this.$refs.MarketTable;
            if (container) {
              container.scrollIntoView({ behavior: "smooth" });
            }
          });
    if (page >= 1 && page <= this.totalPages) {
      this.currentPage = page;
      console.log("this.currentPage",this.currentPage)
      this.updatePaginatedData();
    }
    this.fetchSublistData()
  },
 navigateToMarketPrice(market) {
  const categoryName = this.$route.params.categoryName;
  const district = market.market_name;

  this.created = new Date().toISOString().split('T')[0]; 
  console.log("market-data", this.created);

  this.$router.push({
    name: "pricesublist",
    params: {
      categoryName: categoryName,
      location: `-${district}`,
    },
  });
}

,
   
    // scrollToTop() {
    //   window.scrollTo({
    //     top: 0,
    //     behavior: "smooth" 
    //   });
    // },
    async handleRouteChange(newParams = null) {
      console.log("handleRouteChange")
    //FIXME: i've removed old params
      // console.log('newParams in hrc:',newParams, 'oldParams:', oldParams,);
     
      if(!Object.keys(newParams).length) {
        // console.log("line-624", Object.keys(newParams).length)
        if (!this.selectedCategory) this.selectedCategory = '0';
        if (!this.selectedMarket) this.selectedMarket = '0';
        if (!this.selectedPlace) this.selectedPlace = '0';
        this.fetchInitData()
        this.sublistData = []
      } else {
        // console.log("line-592",this.selectedCategory,this.selectedMarket)
      await this.fetchMarketData();
      if(this.selectedCategory != '0' && this.selectedMarket != '0') {
        // console.log("line-592")
        this.fetchSublistData();
      }
      }
      this.fetchCategoryData();
    },
   
    getMinDate() {
      const today = new Date();
      today.setDate(today.getDate() - 30); // Subtract 30 days
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
    isToday(date) {
      const today = new Date();
      const todayString = today.toISOString().split('T')[0];
      return date === todayString;
    },
    searchMarketData() {
      console.log("searchMarketData")
      this.isLoading = true;
      var params = {}
      params = {
        ...this.$route.params,
      }
  this.Newmarket = [];

      for (let cat of this.categories) {
        if (cat.category_id == this.selectedCategory) {
          params.categoryName = cat?.category_name?.replace(/\s+/g,'-')?.toLowerCase()
          break;
        }
      }

      for (let district of this.markets) {
        if (district.market_id == this.selectedMarket) {
          params.location = district?.market_name?.replace(/\s+/g,'-')?.toLowerCase()
          break;
        }
      }

      if (this.selectedPlace != '0') {
        for (let city of this.placeData) {
          if (city.market_place_id == this.selectedPlace) {
            params.city = city?.place?.replace(/\s+/g,'-')?.toLowerCase()
            break;
          }
        }
      } else params.city = ''
      params.location = params.location && params.location[0] == '-' ? params.location : `-${params.location}`,
      this.isFreshPage = true;
  // this.isLoading = false;
       disableScroll();
      this.$nextTick(() => {
  const mediaQuery = window.matchMedia("(max-width: 768px)");
  if (mediaQuery.matches) { // Check if the screen size matches the condition
    const container = this.$refs.MarketTable;
    if (container) {
      container.scrollIntoView({ behavior: "smooth" });
    }
  }
});

        this.$router.push({
          name: 'pricesublist1',
          params,
          state: {
            selectedDate : this.created
          }
        });
     this.currentPage = 1;
     this.isLoading = false;
    },
    storeIds(data) {
      if (data.sub_category_id) {
        if(data.sub_category_id != this.subCategoryId) {
          // console.log("subcat changing...") 
        this.updateSubCatId(data.sub_category_id)
        }
         else {
        // console.log("subcat not changed")
      }
     } else {
      if(this.selectedCategory != this.categoryId) {
        // console.log("cat changing...")
        this.updateCatId(this.selectedCategory);
      } else {
        // console.log("cat not changed")
      }
     }
    },
    fetchCategoryData(loader = null) {
      const data = {
        categorytype: 'market price'
      };

      if (loader) {
        // console.log("loader on categories")
        this.isLoading = true;} // Set isLoading to true to show the loading spinner

      axios
        .post("https://vaiyal-app.herokuapp.com/categorytype", data)
        .then((response) => {
          this.categories = response.data.data;
          console.log("this.categories",this.categories)
          if (response.data.status == 'success') {
            this.categories = []
            response.data.data.map((category) => {
            if(category.isdatafound) {
              this.categories.push(category)
             
              if (category.category_name.replace(/\s+/,'-').toLowerCase() == this.$route.params?.categoryName) {
                this.selectedCategory = category.category_id
                this.selectedProduct = category.tn_name
              }
            }
          })
          // console.log("response.data.data",response.data.data)

          //TODO: if cat in params, run this loop.
          for (let category of this.categories) {
            if (category.category_name.replace(/\s+/,'-').toLowerCase() == this.$route.params?.categoryName) {
              this.selectedCategory = category.category_id
              this.selectedProduct = category.tn_name
              break;
            }
          }
          // console.log('loader cat 1')
          if (!this.$route.params?.location) this.isLoading = false; 
          this.$nextTick(() => {
            const container = this.$refs.categoryContainer;
            if (container) {
              container.scrollIntoView({ behavior: "smooth" });
            }
          });
          }
        })
        .catch((error) => {
          console.error("Post error:", error);
          // console.log('loader cat finally')
          // this.isLoading = false; 
        });
    },
 async fetchMarketData() {
  try {
    if (this.selectedCategory != 0) {
      const apiUrl = `https://vaiyal-app.herokuapp.com/getdistrict?type=market&categoryid=${this.selectedCategory}`;
      const response = await fetch(apiUrl);
      const data = await response.json();
      
      if (data.status === "success") {
        this.markets = data.data;
       
        if (!this.Newmarket.length) {
          this.Newmarket = [...this.markets];
        }

        let change;
        this.markets.map(dist => {
          if (this.$route.params.location.split('-')[1].replace(/-/g, ' ') === dist.market_name.toLowerCase()) {
            this.marketId = dist.market_id;
            this.selectedMarket = dist.market_id;
            this.selectedDistrict = dist.tn_name;
            change = true;
          }
        });

        if (!change) {
          this.selectedMarket = '0';
        }
      } else {
        console.error("API response error:", data.msg);
      }

      this.fetchPlaceData();
    }
  } catch (error) {
    console.error("Error fetching market data:", error);
  }
}
,

async fetchInitData(){
  fetch("https://vaiyal-app.herokuapp.com/get/recentlist?type=market")
      .then(response => response.json())
      .then(data => {
        if (data.status === "success") {
          this.tumpData = data.data
          this.recentCategory = data.data.categoryName
          console.log("123AAAAA",this.tumpData);
        } else {
          console.error("Failed to fetch recent product data:", data.msg);
        }
      })
      .catch(error => {
        console.error("Error fetching recent product data:", error);
      });
},
  async fetchPlaceData() {
    if (this.selectedMarket) {
      try {
        
        const apiUrl = `https://vaiyal-app.herokuapp.com/getcities?type=market&categoryid=${this.selectedCategory}&marketid=${this.selectedMarket}`;
        const response = await fetch(apiUrl);

        const data = await response.json();
        if (data.status === "success") {
          this.isPlaceDataFetched = true
          this.placeData = [];
          this.placeData = data.data;
          let change;
          for (let i of data.data) {
            if ((i.place.toLowerCase() == this.$route.params?.city?.replace(/-/g,' ')) ) {
              change = true
              if(this.selectedPlace != i.market_place_id) {
                // console.log("mpId 2 changing...") 
                this.selectedPlace = i.market_place_id
                // this.updateMarketPlaceId(i.market_place_id)
                break;
              }
              } 
              else {
                // console.log("mpId 2 not changed", this.selectedPlace, i.market_place_id)
                // if (reset) this.selectedPlace = '0'
              }

              // this.selectedPlace = i.market_place_id
              // this.updateMarketPlaceId(i.market_place_id)
              // change = true;
            
          }
          if(!change) {
            if (this.selectedPlace != '0') {
              // console.log('mpId to 0 (1)',reset)
              this.selectedPlace = '0'
            }
          }
        } else {
          console.error("API response error:", data.msg);
        }
      } catch (error) {
        console.error("Error fetching place data:", error);
      }
    } else {
      this.placeData = []; // Reset placeData if no market is selected
      this.selectedPlace = "0"; // Reset selectedPlace
      // console.log('mpId to 0 (2)')
    }
  },

    fetchSublistData() {
  this.isLoading = true;

  const apiUrl = `https://vaiyal-app.herokuapp.com/getplacemarket?page=${this.currentPage}`;
  const payload = {
    categoryid: this.selectedCategory,
    marketid: this.selectedMarket,
    marketplaceid: this.selectedPlace,
    created: this.created,
  };

  axios
    .post(apiUrl, payload)
    .then((response) => {
      if (response.data.status === "success") {
        this.sublistData = response.data.data.Dates; // All data
        this.totalItems = response.data.data.totalItems; // Total items (from API)
        this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage);
        this.updatePaginatedData();
      } else {
        console.error("API Error:", response.data.msg);
      }
    })
    .catch((error) => {
      console.error("sublist:", error);
    })
    .finally(() => {
      this.isLoading = false;
    });
},

    async updateMetaTags() {
      const categoryName = this.$route.params.categoryName;
      const pageName = "pricesublist";
      const city = this.$route.params.city;
      const district = this.$route.params.location?.split('-')[1] || "Tamilnadu";
      const created = this.created;

      const formatDate = (date) => {
      const d = new Date(date);
      const day = String(d.getDate()).padStart(2, '0');
      const month = String(d.getMonth() + 1).padStart(2, '0');
      const year = d.getFullYear();
      return `${day}-${month}-${year}`;
  };

  const formattedCreatedDate = formatDate(created);
console.log("formattedCreatedDate",formattedCreatedDate)
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const capitalizedCity = capitalizeFirstLetter(city || '');
  const capitalizedDistrict = capitalizeFirstLetter(district || "");

  try {
    const response = await axios.get("https://vaiyal-app.herokuapp.com/meta", {
      params: {
        category_name: categoryName,
        page_name: pageName,
      },
    });

    const metaData = response.data.data;

    if (metaData) {
      const title = metaData.title.replace('{city}', this.$route.params.city?capitalizedCity + "," : "" )
        .replace("{district}", capitalizedDistrict);
      document.title = title;

      const description = metaData.description.replace("{city}", this.$route.params.city?capitalizedCity + "," : ""  )
        .replace("{district}", capitalizedDistrict)
        .replace("{date}", formattedCreatedDate);
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', description);
      }
      const head = metaData.title
                        .replace("{city}", this.$route.params.city?capitalizedCity + "," : "")
                        .replace("{district}", capitalizedDistrict);
       const metaHead = document.querySelector(
                        'meta[name="title"]'
                    );
                    if (metaHead) {
                        metaHead.setAttribute("content", head);
                    }
    }
  } catch (error) {
    console.error('Error fetching meta data:', error);
  }
}

  },
  beforeRouteLeave(to, from, next) {
  if (to.name === "Home") {
      window.location.reload();
  } else {
    next();
  }
}


};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Halant:wght@300&family=Josefin+Sans:wght@500&family=Raleway:wght@500&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Mukta:wght@400;500&family=Noto+Sans+Tamil&family=Satisfy&display=swap');
body{
  font-family: 'Mukta';
}
.header h2 {
  justify-content: center;
  text-align: center;
  font-size: 60px;
  padding: 100px 0px 10px 0px;
  color: rgba(199, 234, 199, 0.9);
  text-transform: capitalize;
}

.product-item {
  margin: 50px 0px;
}

.header h5 {
  justify-content: center;
  text-align: center;
  font-size: 30px;
  margin: 10px 0px 10px 0px;
  color: #ffffff;
}

.colourmaker h6 {
  justify-content: center;
  text-align: center;
  font-size: 16px;
  color: rgba(0, 128, 0, 0.9);
  margin-block: 20px;
}

.contactre {
  margin: 90px 0px 20px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.contactre h5 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #fff;
}

.contactre button {
  padding: 0.3rem 1rem;
  margin-top: 20px;
  margin-bottom: 50px;
  border: 1px solid rgba(255, 255, 255, 0.9);

  border-radius: 6px;
  background-color: transparent;
  color: #fff;
}

.custom-button4:hover {
  background: rgba(255, 255, 255, 0.5);
  color: black;
  transition: background-color 0.3s;

}

.custom-button4:not(:hover) {
  transition: background-color 0.3s;
}

.breadcrumb {
  justify-content: center;
  margin: 30px 0px 50px 0px;
  margin-bottom: 20px;
  text-transform: capitalize;
  color: rgb(231, 221, 221);
}
  


.breadcrumb-item a {
  text-decoration: none;
  color: rgb(231, 221, 221);
}

.breadcrumb-item a:hover {
  color: #888;
}

.listdate {
  margin-top: 30px;
  margin-bottom: 50px;
}

.date {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  margin: 10px 0px;
  padding: 7px 15px 0px 15px;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
}

.div {
  display: flex;
}

.date h5 {
  font-size: 14px;
  color: rgba(0, 128, 0, 0.9) ;

}

.created {
  color: rgba(0, 128, 0, 0.9) ;
}

.nodata {
  display: flex;
  justify-content: center;
  align-items: center;
  color: red;
  padding: 50px;
}

.nodata img {
  width: 600px;
}

.colourmaker .date {
  position: relative;
  text-decoration: none;
}

.colourmaker .date::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 5px;
  height: 100%;
  background-color: rgba(0, 128, 0, 0.9);

  z-index: -1;
}

.colourmaker .date:nth-child(odd)::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 5px;
  height: 100%;
  background-color: rgba(0, 128, 0, 0.9);

  z-index: -1;
}

.colourmaker .date:nth-child(even)::before {
  background-color: rgba(145, 155, 5, 0.9);
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 5px;
  height: 100%;

  z-index: -1;
}

.material-symbols-outlined {
  font-size: 14px;
  color: rgba(0, 128, 0, 0.9);
  padding-right: 5px;
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  color: green;
}

.loading-spinner i {
  font-size: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.price-link {
  text-decoration: none;
  color: black;
}

.price-link:hover {
  color: rgb(133, 133, 3);
}

.onplace {
  display: flex;
  justify-content: space-between;
}
.onplace h1{
  font-size:28px;
  color:#71A600;
}
.marketname {
  display: flex;
}

.marketname p {
  font-size: 12px;
  padding-top: 4px;
  padding-left: 10px;
  color: rgba(145, 155, 5, 0.9);
}


.pagination {
  display: flex;
  justify-content: center;
  margin: 30px 30px;
}

.pagination li {
  list-style: none;
  margin: 0 5px;
}

.pagination a {
  display: block;
  font-size: 12px;
  padding: 8px 12px;
  text-decoration: none;
  color: #333;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: background-color 0.5s ease;
}

.pagination a:hover {
  background: #71A600;
  cursor: pointer;
}

.pagination li.active a {
  background: #71A600;
  color: #fff;
}

.pagination li.disabled a {
  pointer-events: none;
  opacity: 0.6;
}

@media screen and (max-device-width: 600px) {
  .onplace {
    display: block;
  }

  .listdate {
    margin-top: 30px;
    margin-bottom: 50px;
  }

  .header {
    min-height: 50vh;
  }

  .header h2 {
    justify-content: center;
    text-align: center;
    font-size: 60px;
    padding: 50px 0px 10px 0px;
    color: rgba(199, 234, 199, 0.9);
    text-transform: capitalize;
  }

  .nodata img {
    width: 300px;
  }
  .table {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.tpic-align-cenetr{
 width: 100% !important;
    margin-left: auto;
    margin-right: auto;
}

}

@media screen and (max-device-width: 400px) {
  .onplace {
    display: block;
  }
.tpic-align-cenetr{
   width: 100% !important;
    margin-left: auto;
    margin-right: auto;
}
  .listdate {
    margin-top: 30px;
    margin-bottom: 50px;
  }

  .date h5 {
    font-size: 12px;
  }

  .nodata img {
    width: 300px;
  }

 
.table th{
  background: #71A600;
  color: #fff;
  font-size: 14px;
      padding:10px !important;

}
.table td{
  background: #f9ffed;
    padding:2%  !important;

}

.history-icon{
font-size: 12px !important;
}


.tpic-align-cenetr{

margin-left: 0;
margin-right: 0;}
}
.disclaimer p{
  font-size: 14px;
}
.tpic-align-cenetr{
   width: 80%;
    margin-left: auto;
    margin-right: auto;
}
.content p{

  font-size: 14px;
  font-weight: 600;
}


.table th {
  background-color: #f2f2f2;
  color: #16123f;
}

td a {
  text-decoration: none;
  color: green;
}

td a:hover {
  color: darkgreen;
}

td small {
  color: gray;
}

.table-container {
  justify-content: center;
  align-items: center;
  width: 100%;

}

.table {
  border-collapse: collapse;
  margin-top: 20px;
    margin-left: auto;
  margin-right: auto;
}

.table th,
.table td {
  border-top: 1px solid #71A600;
  border-bottom: 1px solid #71A600;
  padding: 10px 40px;
  text-align: left;
  width: 100%;
}
.table th{
  background: #71A600;
  color: #fff;
}
.table td{
  background: #f9ffed;
}

.shimmer-effect {
  width: 100%;
  height: 20px;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
  margin-bottom: 10px;
}

@keyframes shimmer {
  to {
    background-position: -200% 0;
  }
}

h1 span {
  color: green;
}

.shimmer-line {
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
  height: 15px;
  margin-bottom: 10px;
}

.shimmer-line {
  height: 20px;
  width: 100%;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  margin-bottom: 10px;
  border-radius: 10px;
  animation: shimmer 1s infinite linear;
}

@keyframes shimmer {
  to {
    background-position: -200% 0;
  }
}

.related {
  background: rgb(139, 211, 139);
  color: #16123f;
  font-weight: bold;
}
.showdist h2{
  font-size: 20px;
  color:#71A600;
  font-weight:600;
}

.history-icon{
  font-size: 14px;
}

.table-1-web{
  width: 70%;
  margin-left: auto;
  margin-right: auto;
}

select.selec {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    background-image: url("../assets/img/Icons/arrow_drop.png");
    background-size: 26px 26px;
    background-repeat: no-repeat;
    background-position: calc(100% - 10px) center;
    padding: 8px 30px 8px 8px;
    border: 1px solid #ccc;
    border-radius:0 !important ;
    height:38px !important;
    box-shadow: none !important;
  }
  select.tamil-font {
    font-size: 14px;
  }
  select.selec:focus {
    outline: none;
    border-color: #007bff;
  }

.loco {
    padding: 30px 20px;
    height: 100%;
    border-radius: 6px;
  }
  
  .searchbox h6 {
    font-size: 14px;
    color: rgba(70, 67, 67, 0.8);
  }
  
  .searchbox select {
    width: 100%;
    min-width: 200px;
    height: 40px;
    padding: 0px 10px;
    color: rgba(70, 67, 67, 0.8);
    border: 0.5px solid rgba(163, 159, 159, 0.8);
  }

select:disabled {
  background-color: #f5f5f5;
  color: #999; 
  border: 1px solid #ddd;
  cursor: not-allowed; 
  opacity: 0.6; 
}
  
  .dist,
  .town {
    margin: 20px 0px;
    
  }

  .dist button {
    vertical-align: bottom;
    width: 100px;
    margin-top: 56%;
    height: 40px;
    background-color: #71a600;
    border: 1px solid #71a600;
    color: white;
    border-radius: 2px;
  }

  .date-formate{
    border-radius: 0 !important;
    border: 1px solid #AABBCC;
    box-shadow: none !important;
    }
    @media (min-width: 300px) and (max-width: 767px) {
  .dist button {
    margin-top: 3% !important;
    width: 100% !important;
    margin-right: auto;
    margin-left: auto;
    background-color: #71a600;
    border: 1px solid #71a600;
    color: white;
  }
  .pagination a {
 
  padding: 8px 8px !important;
 
}
}

@media (min-width: 768px) and (max-width: 990px) {
  .table-1-web {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
.tpic-align-cenetr{
 width: 100% !important;
    margin-left: auto;
    margin-right: auto;
}
  .dist,
  .town {
    margin: 20px 10px !important;
  }

 
.searchbox select {
  width: 100%;
  min-width: 130px;
  padding: 0px 10px !important;
    font-size: 14px;

}
}
.market-container {
  display: flex;
  flex-wrap: wrap; 
  gap: 5px; 
  text-align:center ;
  justify-content: center;
   width: 70%;
    margin-left: auto;
    margin-right: auto;
}

.inter-links span{
    white-space: nowrap; /* Prevents the text from wrapping */
  text-align:center ;
  font-weight: 600;
  cursor: pointer;
    color: #71A600;
}
.inter-links span:hover{
    color: #ffc971;
  font-weight: 600;
}

.todays-head{
  font-size: 18px;
  color: black !important;
}

.no-data {
  font-size: 20px;
  font-weight: 600;
}

.no-data-para{
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.nodata-img{
  margin-top: -5%;
  width: 200px;
  margin-left: auto;
  margin-right: auto;
}

.nodata-img img{
  width: 100%;
}

.disabled-red {
  cursor: not-allowed;
}

</style>
